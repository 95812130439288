<template>
    <div class="wrap padding-top-layout">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title">{{ $t('list_user_group.title_user_group_setting') }}</h1>
          </div>
        </div>
        <common-button v-ripple="false" class="return__header" @action="goToCreateUserGroup()" :label="buttonRegister">
          <div class="list-emission-return">
            <img class="mr-0 return" src="@/assets/icons/addregistericon.svg" />
            <img class="mr-0 return-active" src="@/assets/icons/addregistericon_active.svg" />
          </div>
        </common-button>
      </div>
      <div
        class="category-table main-table custom-table center-table user-management expanded group"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="listData"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :rowFocus="1"
          :showFocus="false"
          :allowAddNew="false"
          :isShowToolbar="true"
          :isResizeCustome="false"
          @changeFullScreen="onChangeFullScreen"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex';
  import { CollectionView } from '@mescius/wijmo';
  import DataTable from '@/components/category/data-table';
  import { ROUTES } from '@/router/constants';
  import { KEYS_CODE } from '@/constants/keyboard';
  import * as wjGrid from '@mescius/wijmo.grid';
  import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
  import DialogPopupShare from '@/components/dialogs/notification-popup.vue';
  import DialogPopupPrivate from '@/components/dialogs/question-popup.vue';
  import DialogPopupError from '@/components/dialogs/error-popup.vue';
  import { getUsersGroupApi } from '@/api/userManagement';
  import CommonButton from '@/components/utils/button.vue';
  import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
  import { getWidthOfDetailButton } from '@/utils/multiLanguage'
  import { setMinMaxSizeColumns, getWidthByTextContent } from '@/utils/calcTextWidth';
  
  import moment from 'moment';
  export default {
    name: 'ListUserGroupTemplates',
    data() {
      return {
        warningTemplateDataDialogPopup: false,
        listUsersAccessTemplateData: [],
        showTooltip: false,
        listData: null,
        gridColumns: [],
        userGroupsList: [],
        items: [
          {
            text: this.$t('list_user_group.hyperlink_home'),
            disabled: false,
            href: ROUTES.DASHBOARD,
          },
          {
            text: this.$t('list_user_group.hyperlink_setting'),
            disabled: false,
            href: ROUTES.SETTING,
          },
          {
            text: this.$t('list_user_group.hyperlink_user_management'),
            disabled: false,
            href: ROUTES.USER_MANAGEMENT,
          },
          {
            text: this.$t('list_user_group.label_user_group_setting'),
            disabled: true,
            href: ROUTES.GROUPS_MANAGEMENT,
          },
        ],
        flex: null,
      };
    },
    components: {
      DataTable,
      DialogPopupShare,
      DialogPopupPrivate,
      DialogPopupError,
      CommonButton,
    },
    async mounted() {
      const path = this.$route.path;
        if (path.includes(ROUTES.PRODUCTS_EMISSION)) {
            this.items = this.items.map((item, index) => ( {
                ...item,
                href: index === 0 ? `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}` : `${ROUTES.PRODUCTS_EMISSION}${item.href}`,
            }))
        } 
      this.updateBreadCrumb(this.items);
      this.actionUpdateIsLoadingTable(true)
      await Promise.all([this.getListUserGroupData()]);
      this.actionUpdateIsLoadingTable(false)
    },
    computed: {
      ...mapState('userData', ['currentUser']),
      ...mapState('commonApp', ['loadMore', 'isFilter', 'isExpand']),
      ...mapState('registerData', ['isFullScreen', 'isChangedDurationSuccess']),
      buttonRegister() {
        return this.$t('list_user_group.button_register')
      }
    },
    methods: {
      ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
  
      initializeGrid(flexgrid) {
        this.flex = flexgrid;
  
        document.addEventListener('keydown', (event) => {
          if (
            (event.metaKey || event.ctrlKey) &&
            [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
              event.keyCode,
            )
          ) {
            event.preventDefault();
          }
        });
  
        flexgrid.hostElement.addEventListener(
          'keydown',
          (event) => {
            if (event.metaKey || event.ctrlKey) {
              if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
                const currentSelection = flexgrid.selection;
                const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
                flexgrid.selection = cellRange;
  
                // re-select after add more
                setTimeout(() => {
                  flexgrid.selection = cellRange;
                }, 200);
              } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
                const currentSelection = flexgrid.selection;
                flexgrid.selection = new wjGrid.CellRange(0, currentSelection.col);
              } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
                const currentSelection = flexgrid.selection;
                flexgrid.selection = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
              } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
                const currentSelection = flexgrid.selection;
                flexgrid.selection = new wjGrid.CellRange(currentSelection.row, 1);
              }
            }
          },
          false,
        );
        flexgrid.hostElement.addEventListener('mouseover', (e) => {
          if (e.target.parentElement.classList.contains('comment-icon')) {
            e.target.src = require(`@/assets/icons/comment_active.svg`);
          }
        });
        flexgrid.hostElement.addEventListener('mouseout', (e) => {
          if (e.target.parentElement.classList.contains('comment-icon')) {
            e.target.src = require(`@/assets/icons/comment.svg`);
          }
        });
        this.flex.onSelectionChanged(null);
      },
  
      addBlankItemsToView(count) {
        const lastClientId = this.listData.itemCount;
  
        for (let index = 1; index <= count; index++) {
          this.listData.addNew(this.blankData(lastClientId + index));
        }
        this.listData.commitNew();
        this.listData.clearChanges();
      },
  
      onChangeFullScreen(isFullScreen) {
        if (isFullScreen) {
          // this.addBlankItemsToView(100);
          this.scrollToTop();
        } else {
          this.initialView();
        }
      },
      scrollToTop() {
        if (!this.flexGrid) {
          return;
        }
  
        let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
        this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
      },
  
      getSettingIcon(image) {
        if (image) {
          return require(`@/assets/icons/${image}`);
        }
        return '';
      },
  
      changeTooltipImage(event, type) {
        if (type === 'mouseleave') {
          event.target.src = this.getSettingIcon('helper-icon.svg');
          event.target.parentElement.style.cursor = 'default';
          this.showTooltip = false;
        } else {
          event.target.src = this.getSettingIcon('helper-icon_active.svg');
          event.target.parentElement.style.cursor = 'pointer';
          this.showTooltip = true;
        }
      },
  
      async getListUserGroupData() {
        await getUsersGroupApi()
        .then((res) => {
          res.data.forEach((item) => {
            this.userGroupsList.push({
              id: item.id,
              name: item.name,
              countUser: item?.user_count,
              countProduct: item?.product_count,
              countLegalPersonLayer: item?.branch_count,
              createdAt: moment.utc(formatDateTimeDataTable(item?.created_at)).format('YYYY/MM'),
              updateAt:formatDateTimeDataTable(item?.updated_at),
            });
          });
          this.defineTable(this.userGroupsList);
        })
        .catch((error) => console.warn(error));
      },
  
      defineTable() {
        this.gridColumns = this.getGridColumns();
        this.listData = new CollectionView(this.userGroupsList);
        
        this.flex.columnGroups = this.getGridColumns();
        setMinMaxSizeColumns(this.flex, this.userGroupsList);
        this.$nextTick(() => {
          this.scrollToTop();
        });
      },
  
      blankData() {
        return {
          id: null,
          name: '',
          countUser: this.contractorId,
          countProduct: null,
          countLegalPersonLayer: null,
          createdAt: null,
          updateAt: null,
        };
      },
  
      getGridColumns() {
        return [
        {
          header      : " ",
          binding     : "id",
          minWidth    : getWidthOfDetailButton(),
          maxWidth    : getWidthOfDetailButton(),
          allowSorting: false,
          isRequired  : false,
          cssClassAll : "btn-db table-id hide-filter",
            cellTemplate: CellMaker.makeButton({
              text: `<span>${this.$t('list_user_group.button_detail')}</span>`,
              click: (event, context) => this.onIdClicking(context),
            }),
          },
          { 
            header: this.$t('list_user_group.table_user_group_name'),
            binding: 'name',
            allowSorting: false,
            isReadOnly: true,
            minWidth: getWidthByTextContent(this.$t('list_user_group.table_user_group_name')),
            maxWidth: 980,
            cssClassAll: 'single-row',
          
          },
          { 
            header: this.$t('list_user_group.table_number_of_user'),
            binding: 'countUser',
            allowSorting: true,
            isReadOnly: true,
            minWidth: getWidthByTextContent(this.$t('list_user_group.table_number_of_user')),
            maxWidth: 980,
            cssClassAll: 'single-row',
          
          },
          { 
            header: this.$t('list_user_group.table_legal_entity_and_number'),
            binding: 'countLegalPersonLayer',
            allowSorting: true,
            isReadOnly: true,
            minWidth: getWidthByTextContent(this.$t('list_user_group.table_legal_entity_and_number')),
            maxWidth: 980,
            cssClassAll: 'single-row',
          
          },
          { 
            header: this.$t('list_user_group.table_number_of_products'),
            binding: 'countProduct',
            allowSorting: true,
            isReadOnly: true,
            minWidth: getWidthByTextContent(this.$t('list_user_group.table_number_of_products')),
            maxWidth: 980,
            cssClassAll: 'single-row',
          
          },
          { 
            header: this.$t('list_user_group.table_register_date'),
            binding: 'createdAt',
            allowSorting: true,
            isReadOnly: true,
            minWidth: getWidthByTextContent(this.$t('list_user_group.table_register_date')),
            maxWidth: 980,
            cssClassAll: 'single-row',
          
          },
          { 
            header: this.$t('list_user_group.table_last_updated'),
            binding: 'updateAt',
            allowSorting: true,
            isReadOnly: true,
            minWidth: getWidthByTextContent(this.$t('list_user_group.table_last_updated')),
            width: '*',
            cssClassAll: 'single-row',
          
          },
        ];
      },
      onIdClicking(rowData) {
        if (!rowData.item?.id) return;
        const path = this.$route.path;
        if (path.includes(ROUTES.PRODUCTS_EMISSION)) {
            this.$router.push({
            path: ROUTES.PRODUCTS_EMISSION + `${ROUTES.GROUPS_MANAGEMENT}/${ROUTES.DETAIL_USER_GROUP}/${rowData.item?.id}`,
            query: this.$route.query
          });
        } else {
            this.$router.push({
            path:  `${ROUTES.GROUPS_MANAGEMENT}/${ROUTES.DETAIL_USER_GROUP}/${rowData.item?.id}`,
            query: this.$route.query
          });
        }
      
      },
      goToCreateUserGroup() {
        const path = this.$route.path;
        if (path.includes(ROUTES.PRODUCTS_EMISSION)) {
          this.$router.push({
            path: ROUTES.PRODUCTS_EMISSION + ROUTES.GROUPS_MANAGEMENT + ROUTES.REGISTER_USER_GROUP,
            query: this.$route.query
          });
        } else {
          this.$router.push({
            path: ROUTES.GROUPS_MANAGEMENT + ROUTES.REGISTER_USER_GROUP,
            query: this.$route.query
          });
        }
      },
    },
  };
  </script>
  <style lang="scss">
  .edit-cell_custom {
    .wj-cell-maker {
      background: unset !important;
      border: 1px solid $goldMid;
    }
  }
  .group {
    .hide-filter {
      .wj-cell-maker {
        color: $goldMid !important;
        position: unset !important;
        background: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #A4A184;

        &:hover {
          color: #ffffff !important;
          background: #0072a3;
          border: none;
        }
      }
        .wj-btn {
          display: none !important;
        }
    }
  }
  </style>
  <style scoped lang="scss">
 
   .return {
      &__header {
        margin-top: unset;
        margin-right: 40px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 161px;
        .list-emission-return {
          height: 20px;
          .return-active {
            display: none;
          }
        }
        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }
            .return-active {
              display: block;
            }
          }
        }
        &:active {
          .list-emission-return {
            .return {
              display: block;
            }
            .return-active {
              display: none;
            }
          }
        }
      }
  
      &__button-responsive {
        display: none;
      }
    }
  .wrap {
    width: 100%;
    height: 100%;
  
    .wrap__block__header__content {
      display: flex;
      align-items: center;
      
      margin-left: 40px;
      @media (max-width: 1024px) {
        margin-left: 0;
      }
      // tooltip icon
      .helper-icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        position: relative;
  
        img {
          z-index: 1;
        }
  
        &.tooltip {
          display: block;
          justify-content: left;
          background: $monoWhite;
          border-radius: 4px;
          color: $monoBlack;
          margin: 0 auto;
          font-size: 15px;
          padding: 10px;
          height: unset;
          width: 315px;
          max-width: max-content;
          position: absolute;
          right: unset;
          left: 0px;
          bottom: unset;
          z-index: 9999;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
            0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
            0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          position: fixed;
          width: 100%;
        }
      }
    }
  
    &__header {
      @media (max-width: $tablet) {
        margin-left: 0px;
      }
  
      &--p1 {
        max-width: 700px;
      }
  
      p {
        margin: 0px;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
        }
      }
  
      p:last-child {
        padding-top: 48px;
        color: $monoBlack !important;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.05em;
      }
    }
  
    &__block {
      display: flex;
      @media (min-width: 1024px) {
        align-items: center;
        justify-content: space-between;
      }
      @media (max-width: 1024px) {
        flex-direction: column;
        // align-items: center;
        // justify-content: space-between;
      }
      
      padding: 0 0 40px;
      @media (max-width: 768px) {
        padding-left: 0px;
        flex-direction: column;
      }
  
      &__header {
        @media (max-width: 1024px) {
          padding: 0 0 45px;
          margin-top: 12px;
        }
  
        &__content {
          flex-direction: row;
        }
  
        span,
        .wrap-title {
          margin: 0 16px 0 0;
          font-weight: 400;
          font-size: 22px;
          line-height: 36px;
          letter-spacing: 0.05em;
          color: $goldMid;
          @media (max-width: 768px) {
            font-size: 22px;
            line-height: 28px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  @include desktop {
    .return {
      &__header {
        margin-top: unset;
        margin-right: 40px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 161px;
        .list-emission-return {
          height: 20px;
          .return-active {
            display: none;
          }
        }
        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }
            .return-active {
              display: block;
            }
          }
        }
        &:active {
          .list-emission-return {
            .return {
              display: block;
            }
            .return-active {
              display: none;
            }
          }
        }
      }
  
      &__button-responsive {
        display: none;
      }
    }
    .wrap {
      .wrap__block__header__content {
        .helper-icon {
          &.tooltip {
            position: absolute;
            width: 400px;
            max-width: unset;
          }
        }
      }
    }
  }
  </style>
  